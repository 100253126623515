export const hideIntercom = () => {
  if (window.Intercom) {
    window.Intercom('update', { hide_default_launcher: true });
  }
};

export const showIntercom = () => {
  if (window.Intercom) {
    window.Intercom('update', { hide_default_launcher: false });
  }
};

export class RovoIntercomError extends Error {
  constructor(error: unknown) {
    if (error instanceof Error) {
      super(error.message);
      this.stack = error.stack;
      return;
    }
    super('Rovo Intercom Error');
    this.name = 'RovoIntercomError';
  }
}
