import { captureException } from '@sentry/react';
import { useEffect } from 'react';

import { getConfig } from '@townsquare/config';
import { CREATE_AGENT_PAGE, FULL_PAGE_CHAT_PAGE, FULL_PAGE_SEARCH_PAGE } from '@townsquare/config/routes';
import { useOnMount } from '@townsquare/hooks';
import { type User } from '@townsquare/user-store';

import { RovoIntercomError } from './utils';

const INTERCOM_ROVO_WORKSPACE_APP_ID = 'kkvkd7ql';

export const ROVO_ROUTE_NAMES = [FULL_PAGE_SEARCH_PAGE, FULL_PAGE_CHAT_PAGE, CREATE_AGENT_PAGE];

/**
 * Intializes Intercom for Rovo workspace that is separate from the existing Atlas Intercom workspace.
 */
export const RovoIntercom = ({ user }: { user: User }) => {
  useOnMount(() => {
    const bootIntercom = async () => {
      try {
        if (window.Intercom) {
          // Intercom requires the userHash to be preloaded
          const searchConfigurationPromise = await fetch(`${getConfig().searchUrl}/configuration/v1`);
          const { intercomHmac } = await searchConfigurationPromise.json();

          // Prevents strange behaviour due to Intercom caching, no-ops if Intercom is not booted
          window.Intercom('shutdown');

          window.Intercom('boot', {
            app_id: INTERCOM_ROVO_WORKSPACE_APP_ID,
            name: user.name,
            user_id: user.accountId,
            user_hash: intercomHmac,
          });
        }
      } catch (error) {
        captureException(new RovoIntercomError(error));
      }
    };

    bootIntercom();
  });

  useEffect(() => {
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, []);

  return null;
};
